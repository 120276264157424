<template>
  <div class="home-imagepage">
    <ul>
      <li v-for="(item,index) in imgList.slice(0, 4)" :key="index">
        <div class="w-simImg simImg_spec imgScaleBig simImg_spec4">
        <div class="image-w">
        <a :href ="item.url" target="_blank">
            <div class="imgBB">
                <div class="img">
                  <img :src="staticUrl+(item['cover_' + $i18n.locale]?item['cover_' + $i18n.locale]:item.cover)">
                  </div>
            </div>
            <div class="caption w-img-caption">
                <div class="captionbg bg_main"></div>
                <div class="caption-text">
                    <div class="caption-text-in">
                        <h3>{{item['title_' + $i18n.locale]}}</h3>
                        <p v-html="item['description_' + $i18n.locale]"></p>
                    </div>
                </div>
            </div>
            </a> 
          </div>
         </div>
      </li>
    </ul>
	</div>
</template>

<script>
import request from "../../api/request";
export default {
  data() { 
      return {
        imgList:[],
        staticUrl: request.staticUrl, 
      } 
    },
  mounted(){
    this.getImg();
  },
  methods:{
    change (pre, next, index) {
      this.bannerIndex = pre;
    },
    getImg(){
      let _this = this
      request.get('/banners', {
        banner_type__in:'9'
        }).then(function (res) {
        if (res.status == 200) {
          _this.imgList = res.data.results
        }
      })
    },
  },
}
</script>

<style>
.home-imagepage{
  margin-bottom: 100px;
}
.home-imagepage ul{
 	display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
	display: -moz-box; /* Firefox 17- */
	display: -webkit-flex;/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
	display: -moz-flex; /* Firefox 18+ */
	display: -ms-flexbox; /* IE 10 */
	display: flex;/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */  
}
.home-imagepage ul li{
  flex-grow: 1;
}
@media only screen and (max-width: 767px) {
  .home-imagepage ul{
    flex-wrap: wrap;
  }
  .home-imagepage ul li{
    flex-grow: 1;
    width: calc(calc(100% / 2) - 0px)!important;
  }
  .home-imagepage{
    margin-bottom: 6vw;
  }
}
.w-simImg .image-w{
  position:relative;
  overflow:hidden; 
}
.w-simImg img{ 
  max-width: 100%;
}
.simImg_spec .caption h3{ 
  font-size:22px;
  font-weight: bold;
}
.simImg_spec .caption{ 
  overflow:hidden;
}
.imgScaleBig .img{
  overflow:hidden;
}
.imgScaleBig img{
  transition:transform 2s;
}
.imgScaleBig:hover img{
  transform: scale(1.2,1.2);
  -ms-transform: scale(1.2,1.2);
  -moz-transform: scale(1.2,1.2);
  -webkit-transform: scale(1.2,1.2);
  -o-transform: scale(1.2,1.2);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.simImg_spec4 .captionbg{ 
  left:0;
  top:0; 
  opacity:0;
  *filter:alpha(opacity=0);
  filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0)
  ;-webkit-transition:all 0.35s;
  transition:all 0.35s;
}
.simImg_spec4:hover .captionbg{
   opacity:0.7;
   *filter:alpha(opacity=70);
   filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  }
.simImg_spec4 .w-img-caption{
  text-align: center;
  position:absolute;
  left:0; 
  right:0;
  top:0;
  bottom:20px;
  color:#fff;
}
.simImg_spec4 .caption-text {
  position: absolute;
  bottom: 0;
  left: 0;
  top:0;
  right:0;
}
.simImg_spec4 h3,.simImg_spec4 p {
  position: absolute;
  right: 1em;
  left: 1em;
  padding: 10px 0;
  font-size: 18px;
}
.simImg_spec4 p {
  bottom: 1em;
  line-height: 1.5;
  -webkit-transform: translate3d(0,100%,0);
  transform: translate3d(0,100%,0);
}
.simImg_spec4 h3 {
  bottom: 100px;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0,10px,0);
  transform: translate3d(0,10px,0);
}
.simImg_spec4:hover h3 {
  -webkit-transform: translate3d(0,0,0)
  ;transform: translate3d(0,0,0);
}
.simImg_spec4 h3::after {
  position: absolute;
  top: 100%;
  left: 45%;
  width: 30px;
  height: 1px;
  background: #fff;
  text-align: center;
  transform: translate(-50%);
  content: '';
  -webkit-transform: translate3d(0,40px,0);
  transform: translate3d(0,40px,0);
}
.simImg_spec4 h3::after {
  opacity: 0;
  *filter:alpha(opacity=0);
  filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;transition: opacity 0.35s, transform 0.35s;
}
.simImg_spec4 p {
  opacity: 0;
 *filter:alpha(opacity=0);
  filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;transition: opacity 0.35s, 
  transform 0.35s;
}
.simImg_spec4:hover h3::after{
  opacity:1;*filter:alpha(opacity=100); 
  filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);-webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.simImg_spec4:hover p {
  opacity:0.9;
  *filter:alpha(opacity=90); 
  filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=90);-webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.caption-text-in p span{
  color: #fff !important;
  font-size: 18px !important;
  position: absolute;
  right: 1em;
  left: 1em;
  padding: 10px 0;
}
</style>
