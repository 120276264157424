<template>
  <div class="container-fuild home-videopage">
    <!-- pc -->
    <el-row class="pc-video img-wrap" :gutter="20">
      <el-col :span="6" class="img-item" v-for="(item,index) in newsImgList.slice(0, 4)" :key="index">
        <div @click="goDetail(item.id)">
          <div class="img-box">
          <img class="bigger" :src="staticUrl+item.cover" alt="" srcset="">
          </div>        
          <div class="content-wrap">
            <p v-if="item.create_at">{{item.create_at.substring(0, 10)}}</p>
            <p>{{item[`title_${$i18n.locale}`]}}</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- mb -->
    <!-- mb -->
    <swiper class="mb-video" :options="swiperOption" ref="mySwiper" >
      <!-- slides -->
      <swiper-slide v-for="(item,index) in newsImgList.slice(0, 10)" :key="index">
        <div @click="goDetail(item.id)" class="img-item">
          <div class="img-box">
            <img class="bigger" :src="staticUrl+item.cover" alt="" srcset="">
          </div>        
          <div class="content-wrap">
            <p style="color:#848484;margin-top:10px;" v-if="item.create_at">{{item.create_at.substring(0, 10)}}</p>
            <p style="font-weight: bolder;">{{item[`title_${$i18n.locale}`]}}</p>
          </div>
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <div style="bottom:-20px !important;" class="swiper-pagination"  slot="pagination"></div>   <!-- 分页 -->
    </swiper>
  </div>
</template>

<script>
import request from "../../api/request";
export default {
  data() { 
      return { 
        swiperOption: { 
          //是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true 
          notNextTick: true, 
          pagination: '.swiper-pagination', 
          slidesPerView: 'auto', 
          centeredSlides: true, 
          paginationClickable: true, 
          autoplay: 5000,
          spaceBetween: 30, 
          onSlideChangeEnd: swiper => { 
            //这个位置放swiper的回调方法 
            this.page = swiper.realIndex+1; 
            this.index = swiper.realIndex; 
          } 
        },
        staticUrl: request.staticUrl, 
        newsImgList:[],
        fullWidth: 0, 
        sliderStyle:{
            width:''
        },
      } 
    }, 
    //定义这个sweiper对象 
    computed: { 
      swiper() { 
       return this.$refs.mySwiper.swiper; 
      } 
    }, 
    mounted () { 
      this.getNewsImg()
      this.handleResize()
    },
    methods:{
      getNewsImg(){
        let _this = this
        request.get('/news', {
          news_type__in:'5'
          }).then(function (res) {
          if (res.status == 200) {
            _this.newsImgList = res.data.results
          }
        })
      },
      // 详情
      goDetail(id){
        this.$router.push({
          name:'detail',
          params:{id:id}
        })
      },
      handleResize (event) {
        this.fullWidth = document.documentElement.clientWidth;
        this.sliderStyle.width = this.fullWidth+'px'
      },
    },
    created(){
      window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize)
    },
    
}
</script>

<style>
.mb-video{
  display: block;
}
.pc-video{
  display: none !important;
}
.mb-video{
  margin-top:50px ;
}
.mb-video .img-item{
  width: 100%;
}
.mb-video .img-item .img-box{
  width: 100%;
}
.mb-video .img-item .img-box img{
  width: 100%;
}
.home-videopage{
  margin-bottom: 20px;
}
.home-videopage .img-wrap{
  display: -webkit-box; /* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
	display: -moz-box; /* Firefox 17- */
	display: -webkit-flex;/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
	display: -moz-flex; /* Firefox 18+ */
	display: -ms-flexbox; /* IE 10 */
	display: flex;/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
  cursor: pointer;
}
.home-videopage .img-wrap .img-item{
  flex-grow: 1;
}
.home-videopage .img-wrap .img-item .img-box{
  overflow: hidden;
}
.home-videopage .img-wrap .img-item img{
  width: 100%;
  transition:transform 2s;
}
.home-videopage .img-wrap .img-item:hover img{
  transform: scale(1.2,1.2);
  -ms-transform:scale(1.2,1.2);
  -moz-transform:scale(1.2,1.2);
  -webkit-transform:scale(1.2,1.2);
  -o-transform:scale(1.2,1.2);
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.home-videopage .img-wrap .img-item .content-wrap p:nth-child(1){
  margin: 16px 0;
  color: #6a6a6a;
  font-size: 14px;
  overflow: hidden;
  word-wrap: break-word;
}
.home-videopage .img-wrap .img-item .content-wrap p:nth-child(2){
  display: block;
  font-size: 18px;
  color: #1e1e1e;
  transition: color 0.2s;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
@media only screen and (min-width: 767px) {
  .mb-video{
    display: none;
  }
  .pc-video{
    display: block !important;
  }
}
</style>
