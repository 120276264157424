<template>
  <div class="home">
    <!-- 轮播 -->
    <div id="swiper" class="container-fuild">
      <!-- PC -->
      <el-carousel height="780px" class="pc-swiper" @change="((pre, next) => {change(pre, next)})" :interval="4000" arrow="always">
        <el-carousel-item v-for="(item,index) in swiperList" :key="(item['cover_' + $i18n.locale]?item['cover_' + $i18n.locale]:item.cover)">
          <a :href ="item.url">
            <img ref="bannerHeight" style="object-fit:cover;width:100%;height:100%;" :src="staticUrl+(item['cover_' + $i18n.locale]?item['cover_' + $i18n.locale]:item.cover)"/>
            <div id="text">
              <h2
              :class="{'dh':bannerIndex===index || index===0}"
              v-html="item['description_' + $i18n.locale]"
              >
              </h2>
            </div>
          </a>
        </el-carousel-item>
      </el-carousel>
      <!-- PC end -->
      <!-- MB -->
      <swiper class="mb-swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide class="swiper-slide" v-for="(item,index) in swiperList" :key="index">
          <a :href ="item.url">
            <img ref="bannerHeight" style="width:100%;height:100%;" :src="staticUrl+(item['cover_mobile_' + $i18n.locale]?item['cover_mobile_' + $i18n.locale]:item.cover_mobile)"/>
            <div id="text">
              <h2
              :class="{'dh':bannerIndex===index || index===0}"
              v-html="item['description_mobile_' + $i18n.locale]?item['description_mobile_' + $i18n.locale]:item['description_' + $i18n.locale]"
              >
              </h2>
            </div>
          </a>
        </swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-pagination"  slot="pagination"></div>   <!-- 分页 -->
      </swiper>
      <!-- MB end -->
    </div>
    <!-- pc -->
    <div class="pc-con container">
      <!-- 新闻 -->
      <news-page></news-page>
      <!-- 图文 -->
      <image-page></image-page>
      <!-- video -->
      <video-page></video-page>
    </div>
    <div class="shareholder-meeting" v-if="shareholderMeetingShow">
        <img :src="noticeBanner['cover_' + $i18n.locale]?noticeBanner['cover_' + $i18n.locale]:noticeBanner.cover">
        <div @click="hideShareholderMeeting" class="shareholder-meeting-close"></div>
    </div>
  </div>
</template>

<script>
import NewsPage from './NewsPage.vue';
import ImagePage from './ImagePage.vue';
import VideoPage from './VideoPage.vue';
import request from "../../api/request";
export default {
  components: {
      NewsPage,
      ImagePage,
      VideoPage,
  },
  data() {
      let today = new Date();
    return {
        noticeBanner: {
                cover: require('../../assets/img/shareholder_meeting.png'),
            },
        shareholderMeetingShow: today >= new Date("2021/03/16") && today < new Date("2021/04/01"),
      bannerHeight:"780",
      swiperList: [],
      bannerIndex:0,
      staticUrl: request.staticUrl,
      swiperOption: { 
        //是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true 
        notNextTick: true, 
        pagination: '.swiper-pagination', 
        slidesPerView: 'auto', 
        centeredSlides: true, 
        paginationClickable: true, 
        autoplay: 3000,
        spaceBetween: 30, 
        onSlideChangeEnd: swiper => { 
          //这个位置放swiper的回调方法 
          this.page = swiper.realIndex+1; 
          this.bannerIndex = swiper.realIndex; 
        },
        onSlideChangeStart: swiper => { 
          //这个位置放swiper的回调方法 
          this.page = swiper.realIndex+1; 
          this.bannerIndex = swiper.realIndex; 
        }  
      },
    };
  },
  //定义这个sweiper对象 
  computed: { 
    swiper() { 
      return this.$refs.mySwiper.swiper; 
    } 
  },
  mounted(){
    this.banners()
  },
  methods:{
      hideShareholderMeeting () {
          this.shareholderMeetingShow = false
      },
    change (pre, next, index) {
      this.bannerIndex = pre;
    },
    banners(){
      let _this = this
      request.get('/banners', {
        banner_type__in:'1'
        }).then(function (res) {
        if (res.status == 200) {
          _this.swiperList = res.data.results
        }
      })
        request.get('/banners', {
            banner_type__in:'13'
            }).then(function (res) {
            if (res.status == 200) {
                _this.noticeBanner = res.data.results[0]
                if (_this.noticeBanner.extra_data) {
                    let today = new Date();
                    let extra_data = JSON.parse(_this.noticeBanner.extra_data)
                    _this.shareholderMeetingShow = today >= new Date(extra_data.start_date) && today < new Date(extra_data.end_date)
                }
            }
        })
    },
  },
};
</script>

<style>
.mb-swiper{
  display: block;
}
.pc-swiper{
  display: none;
  }
@media only screen and (min-width: 767px) {
  .mb-swiper{
    display: none;
  }
  .pc-swiper{
    display: block;
  }
}
@media screen and (max-width: 767px){
  .el-carousel__arrow{
    display: none;
  }
  #text h3,h2 {
    font-size: 20px !important;
  }
  #text h3,h2 {
    transition: opacity 1s,transform 1s !important;
  }
}
.mb-swiper .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  bottom: 0 !important;
}
.mb-swiper .swiper-pagination-bullet-active{
  background: #fff !important;
}
.mb img{
  width: 100%;
  height: 100%;
}
.mb .swiper-pagination-bullet-active{
  background:#fff;
}
.dh{
  transform: translate3d(-50%, 0, 0);
  opacity: 1;
}
#text{
  position: absolute;
  font-size: 20px;
  bottom: 20%;
  left: 50%; 
  text-align: center;
  width: 100%;
}
#text h3,h2 {
  left: 50%;
  transform: translate(-50%, -50%); 
  text-align: center;
  color: #fff;
  transform: translate3d(-50%,50%, 0);
  opacity: 0;
  transition: opacity 1s,transform 4s;
  line-height: 1.4;
}
#text div h2{
  margin-bottom: 20px;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
.el-carousel__arrow{
  background-color:transparent !important;
  border: 1px solid #fff;
  height: 50px;
  width: 50px;
}
.el-carousel__arrow:hover{
  background-color:rgba(180, 180, 180, 0.5) !important;
}
.el-carousel__button{
  width: 5px !important;
  height: 5px !important;
  border-radius: 50%;
}

.shareholder-meeting {
    position: fixed;
    top: calc(50vh - 285px);
    left: calc(50vw - 250px);
    z-index: 999;
}

.shareholder-meeting-close {
    position: fixed;
    width: 40px;
    height: 40px;
    top: calc(50vh - 275px);
    right: calc(50vw - 260px);
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .shareholder-meeting {
        width: 90vw;
        top: calc(50vh - 51.3vw);
        left: 5vw;
    }
    .shareholder-meeting > img {
        width: 90vw;
    }
    .shareholder-meeting-close {
        top: calc(50vh - 51.3vw);
        right: 5vw;
    }
}
</style>
