<template>
  <div class="home-newspage">
    <div class="newspage-title">NEWS</div>
    <!-- PC -->
    <el-row class="pc-newspage" :gutter="20">
      <el-col :span="8" v-for="(item,index) in newList.slice(0, 3)" :key="index">
        <div @click="goDetail(item.id)" class="grid-content bg-purple">
          <div class="newscont-title">
            {{item[`title_${$i18n.locale}`]}}
          </div>
          <div v-if="item.create_at" class="newscont-date">
            {{item.create_at.substring(0, 10)}}
          </div>
          <div class="newscont-text">
            {{item[`abstract_${$i18n.locale}`]}}
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- mb -->
    <swiper class="mb-newspage" :options="swiperOption" ref="mySwiper">
      <!-- slides -->
      <swiper-slide @click="goDetail(item.id)" v-for="(item,index) in newList.slice(0, 10)" :key="index">
        <div @click="goDetail(item.id)" class="grid-content bg-purple">
          <div class="newscont-title">
            {{item[`title_${$i18n.locale}`]}}
          </div>
          <div v-if="item.create_at" class="newscont-date">
            {{item.create_at.substring(0, 10)}}
          </div>
          <div class="newscont-text">
            {{item[`abstract_${$i18n.locale}`]}}
          </div>
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <div style="bottom: -40px !important;" class="swiper-pagination"  slot="pagination"></div>   <!-- 分页 -->
    </swiper>
  </div>
</template>

<script>
import request from "../../api/request";
export default {
  data() { 
    return { 
      swiperOption: { 
        //是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true 
        notNextTick: true, 
        pagination: '.swiper-pagination', 
        slidesPerView: 'auto', 
        centeredSlides: true, 
        paginationClickable: true, 
        autoplay: 5000,
        spaceBetween: 30, 
        onSlideChangeEnd: swiper => { 
          //这个位置放swiper的回调方法 
          this.page = swiper.realIndex+1; 
          this.index = swiper.realIndex; 
        } 
      },
      newList:[],
      fullWidth: 0, 
      sliderStyle:{
          width:''
      }, 
    } 
  }, 
  //定义这个sweiper对象 
  computed: { 
    swiper() { 
      return this.$refs.mySwiper.swiper; 
    } 
  }, 
  mounted(){
    this.getNews();
    this.handleResize()
  },
  methods:{
    change (pre, next, index) {
      this.bannerIndex = pre;
    },
    getNews(){
      let _this = this
      request.get('/news', {
        news_type__in:'1'
        }).then(function (res) {
        if (res.status == 200) {
          _this.newList = res.data.results
        }
      })
    },
    // 详情
    goDetail(id){
      this.$router.push({
        name:'detail',
        params:{id:id}
      })
    },
    handleResize (event) {
      this.fullWidth = document.documentElement.clientWidth;
      this.sliderStyle.width = this.fullWidth+'px'
    },
  },
  created(){
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style>
.swiper-container{
  overflow: visible !important;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -20px !important;
  left: 0;
  width: 100%;
}
.swiper-pagination-bullet-active{
  background: #000 !important;
}
.home-newspage{
  margin: 20px auto 60px auto;
}
.home-newspage .newspage-title{
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}
.home-newspage .grid-content{
  height:100px;
}
.home-newspage .grid-content div{
  margin-bottom: 5px;
}
.home-newspage .grid-content .newscont-title{
  font-weight: bold; 
  cursor: pointer; 
}
.home-newspage .grid-content .newscont-title:hover{
  text-decoration: underline;
}
.home-newspage .grid-content .newscont-date{
  font-size: 12px;
}
.home-newspage .grid-content .newscont-date,.newscont-text{
  color: #848484;
}
.home-newspage .grid-content .newscont-text{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.home-newspage .el-col-8:nth-child(2){
  border-right: 1px solid #DCDCDC;
  border-left: 1px solid #DCDCDC;
}
.mb-newspage{
  display: block;
}
.pc-newspage{
  display: none;
  }
@media only screen and (min-width: 767px) {
  .mb-newspage{
    display: none;
  }
  .pc-newspage{
    display: block;
  }
  .home-newspage{
    margin: 60px auto 60px auto;
  }
}
</style>
